import "./Farm.css";
import { useParams } from "react-router-dom";
import { useDocument } from "./hooks/useDocument";
import FarmSummaryPublic from "./FarmSummaryPublic";

export default function FarmPublic() {
  const { id } = useParams();
  const { error, document } = useDocument("public_farms", id);

  if (error) {
    return <div className="error">Erro: {error}</div>;
  }
  if (!document) {
    return <div className="loading">Loading.....{id}</div>;
  }

  return (
    <>
        <div className="container">
            <FarmSummaryPublic farm={document} />
        </div>
    </>
  );
}
