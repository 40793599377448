import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker, Polygon } from "@react-google-maps/api";
import QRCode from "react-qr-code";

import './Farm.css';

const API_KEY = "AIzaSyDKlPgnN3dyeXS5jkBaIChDvmzvN0mITos"

/* Calculate the map center from the average of every polygon coordinate */
function mapCenterCalculus(polygonCoordinates) {
  if (!polygonCoordinates) return { lat: 0, lng: 0 };
  let lat = 0;
  let lng = 0;
  polygonCoordinates.forEach((coordinate) => {
    lat += coordinate.lat;
    lng += coordinate.lng;
  });
  return { lat: lat / polygonCoordinates.length, lng: lng / polygonCoordinates.length };
}

export default function FarmSummaryPublic({ farm }) {
  const polygonCoordinates = farm?.polygonCoordinates;
  const mapCenter = mapCenterCalculus(polygonCoordinates);
  const markerPosition = mapCenter;
  const mapZoom = 12;
  const qrcode_link = `https://eudr-isonfarm.web.app/qrcode/${farm.id}`;


  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
    return date.toLocaleString(); // Customize date formatting if needed
  };

  return (
    <div className='container'>
      <div className="farm-summary">
        <div className="farm-details">
          <h2 className="page-title">{farm?.name}</h2>
          <p className="created-by">
            <small>By {farm?.createdBy?.displayName}</small>
          </p>
          <p className="section-title">Details:</p>
          <p className="details">{farm?.details}</p>
          <p className="farm-info">Total Area: {farm?.totalAreaHa} ha</p>
          <p className="farm-info">Annual Production: {farm?.anualProduction} tons</p>
          <p className="farm-info">Address: {farm?.streetAddress}</p>
          <p className="farm-info">City: {farm?.city}</p>
          <p className="farm-info">State: {farm?.state}</p>
          <p className="farm-info">Country: {farm?.country}</p>
          <p className="farm-info">Zip Code: {farm?.zipCode}</p>
          <p className="farm-info">Product: {farm?.product}</p>
          <p className="farm-info">Cultivated Area(Ha): {farm?.cultivatedArea}</p>
          <p className="farm-info">Preservation Area(Ha): {farm?.preservationArea}</p>
          <p className="farm-info">Reserve Area(Ha): {farm?.reserveArea}</p>
        </div>
        <div className='documents'>
          <h2 className='page-title'>Documents</h2>
          <p className="document-item">
              <p>Filename: <b>EUDR Due Diligence</b> {' '}</p>
              <p>Description: Due Diligence Document {' '}</p>
              <p>Download: NOT AVAILABLE YET</p>
          </p>
          <div className="details-group">

                {farm?.files?.length > 0 &&
                    farm.files.map((file) => (
                        <p className="document-item" key={file?.id}>
                            <p>File Name: <b>{file?.title}</b> {' '}</p>
                            <p>Description: {file?.description} {' '}</p>
                            <p>Download: <a href={file?.url} alt={file?.description} target="blank">
                              Link
                            </a>
                            </p>
                        </p>
                    ))
                }
          </div>
        </div>
        <div className="farm-map">
          <h2 className="page-title">Map</h2>
          <div className="map-container">
            <LoadScript
                googleMapsApiKey={API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "400px" }}
                  center={mapCenter}
                  zoom={mapZoom}
                  options={{
                      clickableIcons: false,
                      keyboardShortcuts: false,
                    }}
                >
                  {polygonCoordinates.length > 0 && (
                    <Polygon
                      paths={polygonCoordinates}
                      options={{
                        fillColor: "#0000FF",
                        fillOpacity: 0.35,
                        strokeColor: "#0000FF",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  )}
                  {markerPosition && (
                    <Marker
                      position={markerPosition}
                      onClick={() => {}}
                      options={{ clickable: false }}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
          </div>
        </div>
        <div className="farm-qarcode">
          <h2 className="page-title">QR Code</h2>
          <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "100%" }}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={qrcode_link}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>

        <div className="farm-info">
          <p className="section-title">Farm Images:</p>
          <div className="images">
            {farm?.images?.length > 0 &&
              farm.images.map((image) => (
                <>
                <p> Description: {image.description} </p>
                <img
                  key={image.id}
                  src={image.url}
                  alt={image.description}
                  className="farm-image"
                />
                </>
              ))}
            </div>
          </div>





      </div>
    </div>
  );
}
